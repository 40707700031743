import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import {
  Layout,
  NarrowContent,
  Heading,
  Section,
  Header,
  SEO,
} from "../components";

const NotFoundPage: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t("not_found.title")}>
        <meta name="robots" content="noindex" />
      </SEO>
      <NarrowContent>
        <Header />
        <Section>
          <Heading>{t("not_found.title")}</Heading>
          <p>{t("not_found.page_not_found")}</p>
        </Section>
      </NarrowContent>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default NotFoundPage;
